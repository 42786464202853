import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {ReservationList} from '../../Components/Reservation';
import {L} from '../../AppActions';

function FavoriteList(props) {
  const [user] = useOutlet('user');

  return (
    <Wrapper>
      <h2>{L('all_reservations')}</h2>

      <ReservationList />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 80px 20px;
  max-width: 960px;
  margin: 0 auto;
`;

export default FavoriteList;
